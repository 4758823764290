import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/european-bee-eater/european-bee-eater-main.jpg" imageAlt="European Bee-Eater" mdxType="TextMediaSplit">
      <h1>{`European Bee-Eater`}</h1>
      <p>{`Digital artwork of the European Bee-Eater.`}</p>
      <p>{`Made in Sketchbook.`}</p>
    </TextMediaSplit>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      